import {Grid, IconButton, Typography} from '@mui/material';
import {FileUpload} from '@mui/icons-material';

import {CodeEditor} from '../../code-editor/CodeEditor';
import {InputProps as Props} from '../../../types';

export const File = (props: Props) => {
  const readFile = (event: any) => {
    const fileReader = new FileReader();
    fileReader.readAsText(event.target.files[0]);
    fileReader.onloadend = (_ev: any) => {
      props.onChange && props.onChange(props.id, fileReader.result);
    };
  };

  if (props.onChange === undefined) {
    return <></>;
  }

  return (
    <div className="flex flex-col mt-8">
      <CodeEditor
        className="h-[250px]"
        onChange={(newValue: string | undefined) => {
          props.onChange && props.onChange(props.id, newValue);
        }}
        code={props.value}
      />
      <div className="flex justify-center items-center bg-gray-700 w-full h-10">
        <label htmlFor={`file-${props.placeholder}`}>
          <input id={`file-${props.placeholder}`} onChange={readFile} type="file" style={{display: 'none'}} />
          <IconButton aria-label={`upload ${props.placeholder}`} color="primary" component="span" sx={{color: 'white'}}>
            <Typography variant="subtitle2">Or upload your file here</Typography>
            <FileUpload sx={{marginLeft: '5px'}} />
          </IconButton>
        </label>
      </div>
    </div>
  );
};
