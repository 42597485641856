import {useContext, useEffect, useState} from 'react';

import {Typography} from '@mui/material';
import {AutoAwesome, EmojiEvents, LockOutlined, Rule, Scoreboard, Info} from '@mui/icons-material';

import AppContext from '../../context/AppContext';
import {NewContestProps as Props} from './NewContest.props';
import {Form as FormComponent} from '..';
import {ModuleType} from '../../types/entities/ModuleType';
import {CreateContestRequest} from '../../api/Requests';
import {URLs, sendGetRequest, sendRequest} from '../../api';

export const Form = (props: Props) => {
  const {dispatchError} = useContext(AppContext);

  const [errors] = useState<Record<string, any>>({});

  const [modules, setModules] = useState<Array<ModuleType>>([]);
  const [selectedModule, setSelectedModule] = useState<ModuleType | null>();
  const [isHardDeadline, setIsHardDeadline] = useState<boolean>(props.hard_deadline ?? false);

  useEffect(() => {
    sendGetRequest(URLs.getAllModules)
      .then((response) => {
        const modules: Array<ModuleType> = response.data;
        setModules(modules);
        if (props.moduleId) {
          setSelectedModule(modules.find((module) => module.id === props.moduleId));
        }
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.data && err.response.data.error) {
            dispatchError(err.response.data.error);
          } else {
            dispatchError('There was an error. Please try again later');
          }
        }
      });
  }, []);

  const handleSubmit = async (data: any) => {
    if (!selectedModule) {
      dispatchError('Please select a module');
      return;
    }
    console.log(data);
    const request: CreateContestRequest = {
      title: data.title,
      password: data.password ?? '',
      description: data.description,
      prize: data.prize ?? '',
      rules: data.rules ?? '',
      about: data.about ?? '',
      //scoring: data.scoring ?? '' // TODO: Discuss with Emil / Serban / Cristi
      start_date: data.start_date,
      end_date: data.end_date,
      problems: (data.pairs ?? []).map((pair: any) => pair.problem),
      module_id: selectedModule.id as number,
      hard_deadline: isHardDeadline,
    };

    sendRequest(props.method, props.url, request)
      ?.then((response) => {
        if (response) {
          props.onSuccess();
        }
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.data && err.response.data.error) {
            dispatchError(err.response.data.cause.details[0]?.message);
          } else {
            dispatchError('There was an error. Please try again later');
          }
        }
      });
  };

  if (props.moduleId && !selectedModule) {
    return <></>;
  } // Eduard: Cheap fix until we refactor Forms

  return (
    <div className="flex flex-col justify-between p-10">
      <Typography align="center" variant="h3">
        {props.modalTitle ?? 'Create a contest'}
      </Typography>
      <FormComponent
        button={{
          className: 'mt-25 mb-10',
          label: 'Submit',
          onClick: handleSubmit,
        }}
        formClass="mt-25"
        fullWidth={true}
        inputs={[
          [
            {
              id: 'title',
              error: errors['title'],
              placeholder: 'Contest title*',
              rules: {
                length: [
                  {
                    cond: 'not-empty',
                    message: 'You must include a title',
                  },
                ],
              },
              startAdornment: <AutoAwesome />,
              type: 'input',
              value: props.title,
              xs: true,
            },
            {
              id: 'start_date',
              error: errors['start_date'],
              rules: {},
              startAdornment: <LockOutlined />,
              type: 'date',
              value: props.startDate,
              xs: 'auto',
            },
            {
              id: 'end_date',
              error: errors['end_date'],
              rules: {},
              startAdornment: <LockOutlined />,
              type: 'date',
              value: props.endDate,
              xs: 'auto',
            },
            {
              id: 'module-type',
              error: errors['module-type'],
              onChange: (newModuleName: string) => {
                setSelectedModule(modules.find((module) => module.module_name == newModuleName));
              },
              options: modules.map((module) => module.module_name),
              placeholder: 'Module type',
              rules: {},
              type: 'dropdown',
              value: selectedModule?.module_name,
            },
          ],
          [
            {
              id: 'contest-pass',
              error: errors['password'],
              className: '',
              placeholder: 'Optional password',
              rules: {},
              startAdornment: <LockOutlined />,
              type: 'input',
            },
          ],
          [
            {
              id: 'hard_deadline',
              name: 'hard_deadline',
              className: '',
              error: errors['hard_deadline'],
              rules: {},
              title: 'Hard-Deadline Contest',
              type: 'checkbox',
              subTitle: "Caution: A hard-deadline contest can't be accesesd after the end date (useful for exams)",
              value: !!isHardDeadline,
              onChange: () => {
                setIsHardDeadline((prevState) => !prevState);
              },
            },
          ],
          [
            {
              id: 'prize',
              className: 'mt-10',
              error: errors['prize'],
              placeholder: 'Prize',
              rules: {},
              startAdornment: <EmojiEvents />,
              type: 'markdown',
              value: props.prize ?? 'Is there any prize involved?',
            },
          ],
          [
            {
              id: 'rules',
              error: errors['rules'],
              placeholder: 'Rules',
              rules: {},
              startAdornment: <Rule />,
              type: 'markdown',
              value: props.rules ?? 'Fill in any specific rules',
            },
          ],
          // [
          //   {
          //     id: 'scoring',
          //     error: errors['scoring'],
          //     placeholder: 'Describe the scoring rules',
          //     rules: {},
          //     startAdornment: <Scoreboard />,
          //     type: 'input',
          //     value: '', //props.scoring,
          //     disabled: true,
          //   },
          // ],
          [
            {
              id: 'about',
              error: errors['about'],
              placeholder: "What's this contest about?",
              rules: {},
              startAdornment: <Info />,
              type: 'input',
              value: props.about,
            },
          ],
          [
            {
              className: 'mt-10',
              id: 'description',
              error: errors['description'],
              placeholder: 'Describe the contest in a few words*',
              rules: {
                length: [
                  {
                    cond: 'not-empty',
                    message: 'You must include a description',
                  },
                ],
              },
              type: 'textarea',
              value: props.description,
            },
          ],
          [
            {
              className: 'mt-25',
              id: 'pairs',
              error: errors['pairs'],
              maxNoEntries: 100,
              rules: {},
              title: 'Problems',
              type: 'problem',
              value: props.pairs,
            },
          ],
        ]}
      />
    </div>
  );
};
