import {Result} from './Result';
import {fromDate} from '../../utils/date-utils';

export class Submission {
  id: number;
  compiled: boolean;
  code: string;
  date: Date;
  error: string | undefined;
  grade: string;
  results: Array<Result>;
  comments: string;

  static PENDING: Submission = new Submission({});

  constructor(data: any) {
    this.compiled = data.compiled;
    this.code = data.code;
    this.date = data.date;
    this.error = data.error;
    this.grade = data.grade;
    this.results = (data.results ?? []).map((data: any) => new Result(data));
    this.comments = data.comments;
    this.id = data.id;
  }

  public dateString(): string {
    return fromDate(this.date);
  }

  public static fromResponse(submission: any) {
    const runOutput = submission.result;

    return new Submission({
      compiled: runOutput.compiled,
      code: submission.code,
      date: new Date(submission.created_at),
      error: runOutput.compiled ? undefined : runOutput.error,
      grade: submission.grade ?? '0',
      results: runOutput.results ?? [],
      comments: submission.comments,
      id: submission.id,
    });
  }

  public static fromArrayResponse(submissions: Array<any>) {
    return submissions
      .map((submission) => this.fromResponse(submission))
      .sort((first: Submission, second: Submission) => second.date.getTime() - first.date.getTime());
  }
}
