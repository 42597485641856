import {useState, useReducer} from 'react';

import {Grid, Typography} from '@mui/material';
import {styled} from '@mui/system';
import {Close, Done} from '@mui/icons-material';

import {CardProps as Props} from './Card.props';
import {Option} from '../Option';
import {Button} from '../button/Button';
import {updateProperty} from '../../../utils';
import {Submission} from '../../../types';

const Row = styled(Grid)(() => ({
  backgroundColor: 'white',
  borderRadius: '10px',
  boxSizing: 'border-box',
  width: '100%',
}));

const Grade = styled(Grid)(() => ({
  alignItems: 'center',
  borderRadius: '0 10px 10px 0',
  boxSizing: 'border-box',
  display: 'flex',
  fontWeight: 600,
  justifyContent: 'center',
  padding: '10px 20px 10px 20px',
}));

const Options = styled(Grid)(() => ({
  backgroundColor: '#D6D6D6',
  borderRadius: '0 0 10px 10px',
  boxSizing: 'border-box',
  padding: '10px',
}));

export const Card = (props: Props) => {
  const [showOptions, setShowOptions] = useState(false);
  const [show, setShow] = useState<Record<string, any>>({});
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const options = Option.fromSubmission(props);

  if (props.submission === Submission.PENDING) {
    return (
      <Row container alignItems="stretch" justifyContent="space-between">
        <Grid item xs>
          <Grid container direction="column" sx={{padding: '10px'}}>
            <Grid item>
              <Typography variant="h5_black">{`Submission ${props.index}`.toUpperCase()}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1_black">Pending...</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grade item className="pending-submission" xs="auto">
          0
        </Grade>
      </Row>
    );
  }

  return (
    <Grid container direction="column">
      <Grid item>
        <Row
          container
          alignItems="stretch"
          justifyContent="space-between"
          onClick={() => setShowOptions(!showOptions)}
          sx={{cursor: 'pointer'}}
        >
          <Grid item xs>
            <Grid container direction="column" sx={{padding: '10px'}}>
              <Grid item>
                <Typography variant="h5_black">{`Submission ${props.index}`.toUpperCase()}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1_black">{props.submission.dateString()}</Typography>
              </Grid>
              {props.userDetails && <Typography variant="subtitle1_black">{props.userDetails.email}</Typography>}
            </Grid>
          </Grid>
          <Grade item className={`${props.submission.error ? 'failed' : 'passed'}-submission`} xs="auto">
            {props.submission.grade}
          </Grade>
        </Row>
      </Grid>
      <div className="pt-2 pr-1 pl-3">
        {showOptions && (
          <Options container direction="column" spacing={1}>
            {props.submission.results.map((result, index: number) => (
              <div key={index} className="flex flex-row justify-between py-2 w-full">
                <div>
                  <Typography variant="h5_black">{`Test ${index + 1}, ${result.status.toLowerCase()}`}</Typography>
                </div>
                <div>
                  <Typography variant="h5_black">
                    {result.status.toLowerCase() === 'passed' ? <Done color="success" /> : <Close color="warning" />}
                  </Typography>
                </div>
              </div>
            ))}
            {props.submission.error && (
              <>
                <Typography variant="h5_black">Compilation error</Typography>
                <Typography variant="subtitle1_black">{props.submission.error}</Typography>
              </>
            )}
            <Grid container alignItems="flex-end" justifyContent="flex-end" spacing={1}>
              {options.map((option) => (
                <Button
                  key={`option-${option.id}`}
                  option={option}
                  show={show[option.id!]}
                  setShow={(newShow: boolean) => {
                    setShow(updateProperty(show, option.id!, newShow));
                    forceUpdate();
                  }}
                />
              ))}
            </Grid>
          </Options>
        )}
      </div>
    </Grid>
  );
};
