import {useEffect, useState, useContext} from 'react';
import {useNavigate} from 'react-router-dom';

import {EmojiEvents, Category as CategoryIcon} from '@mui/icons-material';
import {Typography} from '@mui/material';

import {Form as FormComponent} from '..';
import AppContext from '../../context/AppContext';
import {CreateProblemRequest, sendGetRequest, sendRequest} from '../../api';
import {NewProblemProps as Props} from './NewProblem.props';
import {Test} from '../../types/entities/Test';
import {Category} from '../../types/entities/Category';

export const Form = (props: Props) => {
  const navigate = useNavigate();

  const {dispatchError} = useContext(AppContext);
  const [errors] = useState<Record<string, any>>({});
  // TODO: language id to string translation
  const [language, setLanguage] = useState<string>(props.language ?? 'C');
  const [skeleton, setSkeleton] = useState<string>(props.skeleton || '');

  const [allCategories, setAllCategories] = useState<Array<Category>>();

  useEffect(() => {
    sendGetRequest('/category/all')
      .then((response) => {
        setAllCategories(response.data);
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.data && err.response.data.cause) {
            dispatchError(err.response.data.cause.details[0]?.message);
          } else {
            dispatchError('There was an error. Please try again later');
          }
        }
      });
  }, []);

  const handleSubmit = async (data: any) => {
    // making sure we don't send data we don't need
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {difficulty, test, language, ...others} = data;

    // Change this when we want to support private problems
    const DEFAULT_VISIBILITY = true;

    const request = {
      ...others,
      // TODO: add proper language to language.id translation
      language_id: 1,
      difficulty: difficulty ?? 'easy',
      tests: test ? test.map((test: Test) => ({...test, grade: parseInt(test.grade, 10)})) : [],
      visible: DEFAULT_VISIBILITY,
      skeleton: skeleton,
    } as CreateProblemRequest;

    await sendRequest(props.method, props.url, request)
      .then((response) => {
        if (response) {
          if (response.status === 200 || response.status === 201) {
            if (props.onSuccess) {
              props.onSuccess();
            }

            if (response.data?.id) {
              navigate(`/problem/${response.data?.id}`, {replace: true});
            }
          }
        }
        window.location.reload();
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.data && err.response.data.error) {
            dispatchError(err.response.data.cause.details[0]?.message);
          } else {
            dispatchError('There was an error. Please try again later');
          }
        }
      });
  };

  return (
    <div className="flex flex-col justify-between p-10">
      <Typography align="center" variant="h3">
        Create a problem
      </Typography>
      <FormComponent
        button={{
          className: 'mt-25 mb-10',
          label: 'Submit',
          onClick: handleSubmit,
        }}
        formClass="mt-25"
        fullWidth={true}
        inputs={[
          [
            {
              id: 'title',
              error: errors['title'],
              placeholder: 'Problem title',
              rules: {
                length: [
                  {
                    cond: 'not-empty',
                    message: 'You must include a title',
                  },
                ],
              },
              startAdornment: <EmojiEvents />,
              type: 'input',
              value: props.title,
            },
            {
              // todo: proper language translation
              id: 'language',
              error: errors['language'],
              onChange: (newLanguage: string) => setLanguage(newLanguage),
              options: ['Java'],
              placeholder: 'Language',
              rules: {},
              type: 'dropdown',
              value: props.language,
            },
            {
              id: 'difficulty',
              error: errors['difficulty'],
              options: ['easy', 'medium', 'hard'],
              placeholder: 'Difficulty',
              rules: {},
              type: 'dropdown',
              value: props.difficulty,
            },
          ],
          [
            {
              id: 'categories',
              error: errors['categories'],
              placeholder: 'Categories',
              rules: {},
              startAdornment: <CategoryIcon />,
              type: 'multiselect',
              selectedOptions: props.categories.map((category) => ({label: category.title, value: category.id})) ?? [],
              options: allCategories?.map((category) => ({label: category.title, value: category.id})) ?? [],
            },
          ],
          [
            {
              id: 'description',
              error: errors['description'],
              minRows: 2,
              placeholder: 'Description',
              rules: {
                length: [
                  {
                    cond: 'not-empty',
                    message: 'You must include a description',
                  },
                ],
              },
              type: 'markdown',
              value: props.description,
            },
          ],
          [
            {
              id: 'skeleton',
              error: errors['skeleton'],
              language: language,
              onChange: (newCode: string) => setSkeleton(newCode),
              rules: {},
              title: 'Skeleton',
              type: 'code',
              value: skeleton,
              className: 'h-[70vh]',
            },
          ],
          [
            {
              id: 'test',
              error: errors['test'],
              maxNoEntries: 10,
              rules: {},
              title: 'Tests',
              type: 'test',
              value: props.test,
            },
          ],
        ]}
      />
    </div>
  );
};
