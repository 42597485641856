import {useEffect, useState} from 'react';
import Select from 'react-select';

import {Button} from '@mui/material';
import {useTheme} from '@mui/system';

import {sendGetRequest, sendPostRequest, URLs} from '../../../api';
import {FindUsersEmailResponse, UserContactInfo} from '../../../api/responses/FindUserByEmailResponseType';
import {Color} from '../../../types';
import {Group} from '../../../types/entities/Group';
import {dropdownStylesConfig} from '../../../components/form/dropdown/Dropdown';

export type UserAssignProps = {
  selectedGroup: Group;
  onAssignSuccess: () => void;
  onAssignFail: (error: any) => void;
};

export const UserAssign = (props: UserAssignProps) => {
  const theme = useTheme();
  const dropdownStyles = dropdownStylesConfig();

  const [foundUsers, setFoundUsers] = useState<Array<UserContactInfo>>([]);
  const [email, setEmail] = useState<string>('');
  const [query, setQuery] = useState<string>('');
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  const [buttonDisabled, setButtonDisabled] = useState(false);

  const onUserAssign = (groupId: number) => {
    sendPostRequest(URLs.assignUserToGroup, {groupId, userEmail: email})
      .then((_response) => {
        setEmail('');
        setQuery('');
        setButtonDisabled(false);

        props.onAssignSuccess();
      })
      .catch((err) => {
        setButtonDisabled(false);
        if (err) {
          props.onAssignFail(err);
        }
      });
  };

  useEffect(() => {
    if (query && query.length >= 3) {
      setDataLoading(true);
      const emailsDebounce = setTimeout(() => {
        sendGetRequest(URLs.findUsersByEmail(query))
          .then((response) => {
            const usersData: FindUsersEmailResponse = response.data;
            setFoundUsers(usersData);
            setDataLoading(false);
          })
          .catch((err) => {
            setButtonDisabled(false);
            if (err) {
              props.onAssignFail(err);
            }
          });
      }, 1000);
      return () => clearTimeout(emailsDebounce);
    } else {
      setFoundUsers([]);
      setDataLoading(false);
    }
  }, [query]);

  return (
    <div className="flex items-center gap-3">
      <div className="w-80">
        <Select
          isClearable={true}
          isSearchable={true}
          isLoading={dataLoading}
          styles={dropdownStyles}
          placeholder="Enter email"
          menuPlacement="auto"
          onInputChange={(newValue) => setQuery(newValue)}
          noOptionsMessage={({inputValue}) =>
            query.length >= 3 ? `No users found for "${inputValue}"` : 'Keep typing...'
          }
          options={foundUsers.map((user) => {
            const label = `${user.email} - ${user.lastName} ${user.firstName}`;
            return {
              label: label,
            };
          })}
          onChange={(option, actionMeta) => {
            if (actionMeta.action === 'select-option') {
              const selectedOption = option as {label: string};
              setEmail(selectedOption.label.split(' ')[0]);
              setQuery(selectedOption.label.split(' ')[0]);
            }
          }}
        />
      </div>

      <Button
        disabled={buttonDisabled || email === ''}
        color={Color.SECONDARY}
        variant="contained"
        size="small"
        onClick={() => {
          setButtonDisabled(true);
          onUserAssign(props.selectedGroup.id);
        }}
        sx={{
          '&.Mui-disabled': {
            background: theme.palette.primary.dark,
            color: theme.palette.gridPrimary.contrastText,
          },
        }}
      >
        Assign User
      </Button>
    </div>
  );
};
