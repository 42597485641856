export enum Language {
  C = 'C',
  CPP = 'C++',
  CPP2 = 'cpp',
  GO = 'Go',
  JAVA = 'Java',
  JAVASCRIPT = 'JavaScript',
  PYTHON = 'Python',
  RUBY = 'Ruby',
  BASH = 'bash',
}
