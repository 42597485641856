export enum Role {
  STUDENT = 'student',
  TEACHER = 'teacher',
  ADMIN = 'admin',
  CREATOR = 'creator',
  GUEST = 'guest',
}

export enum RoleCode {
  STUDENT = 1,
  TEACHER = 2,
  ADMIN = 3,
  CREATOR = 4,
  GUEST = 5,
}
