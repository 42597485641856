import {useState} from 'react';

import {Button, Typography} from '@mui/material';

import {URLs, sendPostRequest} from '../../../api';
import {ImportUsersInBulkResponse, UserAndGroupsNames} from '../../../api/responses/ImportUsersInBulkResponseTypes';
import {User} from '../../../types';

function ImportUsersView() {
  const [selectedFile, setSelectedFile] = useState<File>();
  const [error, setError] = useState<string>();

  const [importedUsers, setImportedUsers] = useState<Array<User>>();
  const [failedUsers, setFailedUsers] = useState<Array<UserAndGroupsNames & {error: string}>>();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleUpload = () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile);

      sendPostRequest(URLs.importUsers, formData)
        .then((res) => {
          setSelectedFile(undefined);
          setError(undefined);

          const response = res.data as ImportUsersInBulkResponse;

          setImportedUsers(response.importedUsers);
          setFailedUsers(response.failedUsers);
        })
        .catch((err) => {
          if (err && err.response && err.response.data && err.response.data.message) {
            setError(err.response.data.message);
          } else {
            setError('An error occurred while importing users.');
          }
        });
    }
  };

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-2">
        <Typography variant="h3">Import Users</Typography>
        <Typography variant="body1">
          Import your users by uploading the CSV file. Once the import is successful, activation emails will be sent to
          all imported users.
        </Typography>
      </div>
      <div className="flex flex-col gap-2">
        <Typography variant="body1">
          The CSV file should be formatted as follows (this should be the first line):
        </Typography>
        <span className="bg-gray-800 text-yellow-400 p-2 rounded-md w-fit">
          First Name,Last Name,Nickname,Email,group1;group2;group3
        </span>
      </div>

      <Typography variant="body1">Select a CSV file to upload:</Typography>
      <input type="file" name="file" accept=".csv" onChange={handleFileChange} />
      <Button variant="contained" onClick={handleUpload} disabled={!selectedFile}>
        Upload
      </Button>

      <div className="flex flex-col gap-2">
        {error && <div className="text-red-600 font-bold">{error} - Operation aborted!</div>}
        {importedUsers && importedUsers.length > 0 && (
          <div className="flex flex-col gap-2">
            <span className="text-green-600 font-semibold">
              The following users were imported and assigned successfully:{' '}
            </span>
            <div className="flex flex-col gap-2">
              {importedUsers.map((user: User) => (
                <div key={user.id} className="flex flex-row gap-2">
                  <Typography variant="body1">
                    {user.firstName} {user.lastName}
                  </Typography>
                  <Typography variant="body1">({user.email})</Typography>
                </div>
              ))}
            </div>
          </div>
        )}
        {failedUsers && failedUsers.length > 0 && (
          <div className="flex flex-col gap-2">
            <span className="text-red-600 font-semibold">The following users failed to be imported: </span>
            <div className="flex flex-col gap-2">
              {failedUsers.map((user: UserAndGroupsNames & {error: string}) => (
                <div key={user.email} className="flex flex-row gap-2">
                  <Typography variant="body1">
                    {user.firstName} {user.lastName} - ({user.email}) - Reason: {user.error}
                  </Typography>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ImportUsersView;
