import * as React from 'react';
import {useContext, useReducer, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import {Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, Paper, Typography} from '@mui/material';
import {useTheme} from '@mui/system';

import AppContext from '../../../context/AppContext';
import {ContestsViewContest} from '../../../api/responses/ContestsViewResponseTypes';
import {EnrollmentStatus} from '../../../types';
import {formatDate} from '../../../utils/date-utils';
import {contestsPaginationStyle, contestsTableStyles} from './contest-table-styles';
import {ContestsEnhancedTableHead} from './ContestsTableHeader';
import {getStatus} from './utils';
import {getComparator, stableSort} from '../../../utils/tables/TableSorting';

export interface Data {
  title: string;
  start_date: Date;
  end_date: Date;
  group_name: string;
  action: any;
}

export type ContestsTableProps = {
  contests: Array<ContestsViewContest>;
  query: string;
};

export type Order = 'asc' | 'desc';

export const ContestsTable = ({contests, query}: ContestsTableProps) => {
  const params = new URLSearchParams(location.search);

  const [order, setOrder] = React.useState<Order>((params.get('order') as Order) || 'desc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>((params.get('orderby') as keyof Data) || 'start_date');
  const [page, setPage] = React.useState(0);
  const [dense, _setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const context = useContext(AppContext);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const navigate = useNavigate();

  const theme = useTheme();

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';

    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);

    navigate(`/contests?orderby=${property}&order=${isAsc ? 'desc' : 'asc'}`, {replace: true});
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - contests.length) : 0;

  const updateStatus = (newStatus: EnrollmentStatus, contest: ContestsViewContest) => {
    if (newStatus === EnrollmentStatus.ENROLLED) {
      context.user?.enrollToContest(contest.id);
    }
    forceUpdate();
  };

  useEffect(() => {
    setPage(0);
  }, [query]);

  return (
    <div className="w-full py-2">
      <TableContainer component={Paper}>
        <Table sx={contestsTableStyles(theme)} aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'}>
          <ContestsEnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={contests.length}
          />
          <TableBody>
            {stableSort(contests, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((contest: ContestsViewContest, index) => (
                <TableRow hover tabIndex={-1} key={`${contest.title}-${index}`} id={`contest-row-${index}`}>
                  <TableCell>{contest.title}</TableCell>
                  <TableCell>{formatDate(contest.start_date.toString())}</TableCell>
                  <TableCell>{formatDate(contest.end_date.toString())}</TableCell>
                  <TableCell>{contest.group_name}</TableCell>
                  <TableCell>
                    {getStatus(index, contest, context.user, updateStatus)}
                    {/* <Button size="small" variant="outlined">View</Button> */}
                  </TableCell>
                </TableRow>
              ))}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: (dense ? 33 : 43) * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        {contests.length === 0 && (
          <div className="flex flex-col justify-center items-center py-4 gap-2 bg-card-dark">
            <Typography variant="h5">The contests will be published here soon 🚀</Typography>
          </div>
        )}
      </TableContainer>
      <TablePagination
        sx={contestsPaginationStyle(theme)}
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={contests.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};
