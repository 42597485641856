import {Children} from 'react';
import {NavLink} from 'react-router-dom';

import {Button, Logo} from '../../index';
import GenericMenuProps from '../generic-menu/GenericMenu.props';

/**
 * Generic menu consisting of the logo, a list of options and a set of buttons.
 */
const DesktopMenu = (props: GenericMenuProps) => (
  <div className="flex flex-row items-center justify-between py-4 min-w-full max-w-full box-border bg-background-default">
    <NavLink to={props.logo}>
      <Logo />
    </NavLink>
    <div className="flex flex-row">
      <div className="flex flex-row gap-10 mr-6 items-center">
        {Children.toArray(
          props.links.map((link, index) => (
            <NavLink className="no-underline text-base font-semibold text-text-main" to={link.to}>
              {link.text.toUpperCase()}
            </NavLink>
          ))
        )}
      </div>
      <div className="flex flex-row gap-4 items-center">
        {Children.toArray(
          props.buttons.map(({color, link, onClick, text, size}) => (
            <Button color={color} label={text} link={link} onClick={onClick} size={size} />
          ))
        )}
      </div>
    </div>
  </div>
);

export default DesktopMenu;
