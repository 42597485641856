import {useContext, useEffect, useMemo, useReducer, useState} from 'react';
import {Link, Outlet, useLocation, useParams, useNavigate} from 'react-router-dom';

import {Typography, IconButton} from '@mui/material';
import {Edit, ArrowBackIosNew} from '@mui/icons-material';

import AppContext from '../../context/AppContext';
import {sendGetRequest, URLs} from '../../api';
import {ContestViewData, ContestViewResponse} from '../../api/responses/ContestViewResponseTypes';
import {Page, ModalWithButton, NewContestForm} from '../../components';
import {stringInterval} from '../../utils/date-utils';
import {Role} from '../../types';

export type ContestContextType = {
  contest: ContestViewData;
  isOwner: boolean;
  groupContestId: number;
};
export const Contest = () => {
  const context = useContext(AppContext);
  const {id, groupContest: groupContestId} = useParams();

  const [contest, setContest] = useState<ContestViewData | null>(null);
  const [isPrivileged, setIsPrivileged] = useState(false);

  const [update, forceUpdate] = useReducer((x) => x + 1, 0);

  const location = useLocation();

  const navigate = useNavigate();

  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    sendGetRequest(`${URLs.getContest}/${parseInt(id || '0')}?groupContestId=${groupContestId}`)
      .then((response) => {
        const contestData: ContestViewResponse = response.data;
        // TODO: No Enrolled Users ATM
        setContest(contestData);
        setIsPrivileged(
          contestData.created_by === Number(context.user?.id) ||
            context.user?.role === Role.ADMIN ||
            context.user?.role === Role.CREATOR
        );
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.data && err.response.data.error) {
            context.dispatchError(err.response.data.error, '/contests');
          } else {
            context.dispatchError('There was an error. Please try again later', '/contests');
          }
        }
      });
  }, [context.user, id, groupContestId, update]);

  const tabs = useMemo(
    () => getTabs(context.user?.role === Role.TEACHER || context.user?.role === Role.ADMIN),
    [context.user?.role]
  );

  return (
    <Page>
      {contest ? (
        <div className="flex items-center">
          <div className="grid grid-cols-12 item-center justify-center rounded-2xl bg-card-main mb-10 min-w-[90vw]">
            <div className="col-span-12 min-h-[85vh] min-w-[90vw]">
              <div className="min-h-[83vh]">
                <div className="px-6 py-4">
                  <div className="flex items-center justify-start">
                    <IconButton onClick={() => navigate(-1)}>
                      {' '}
                      <ArrowBackIosNew sx={{fontSize: 54}} className=" text-white" />{' '}
                    </IconButton>
                    <div className="flex justify-start flex-col ml-3">
                      <Typography variant="h3">{contest.title}</Typography>
                      <div className="flex">
                        <Typography variant="h5_italic">
                          {stringInterval(new Date(contest.start_date), new Date(contest.end_date))}
                        </Typography>

                        {isPrivileged && (
                          <ModalWithButton
                            button={{
                              element: (
                                <Edit
                                  onClick={() => setEditMode(true)}
                                  sx={{cursor: 'pointer', fontSize: '1.5rem', ml: '5px'}}
                                />
                              ),
                            }}
                            children={[
                              <NewContestForm
                                title={contest.title}
                                startDate={contest.start_date}
                                endDate={contest.end_date}
                                about={contest.about}
                                description={contest.description}
                                prize={contest.prize}
                                rules={contest.rules}
                                //scoring={''}
                                hard_deadline={contest.hard_deadline}
                                pairs={contest.problems.map((problem) => ({
                                  problem: problem.title,
                                  quota: 1, // TODO
                                }))}
                                url={`${URLs.updateContest}/${contest.id}`}
                                method="put"
                                onSuccess={() => {
                                  setEditMode(false);
                                  forceUpdate();
                                }}
                                modalTitle={'Edit contest'}
                                moduleId={contest.module_id}
                              />,
                            ]}
                            className="allowScrollbar"
                            closeModal={() => setEditMode(false)}
                            height={window.innerHeight * 0.9}
                            open={editMode}
                            openModal={() => setEditMode(true)}
                            overflow="scroll"
                            width={window.innerWidth * 0.75}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-row justify-evenly gap-1 py-3 px-1 text-center h-[100%] bg-primary-main">
                  {tabs.map((tab) => (
                    <Link key={`${tab.name}`} to={tab.path} replace={true} className="no-underline">
                      <Typography
                        style={{
                          color: location.pathname.includes(tab.path) ? '#ffffff' : '#C5C5C5',
                        }}
                        variant="h6"
                      >
                        {typeof tab.name === 'string' ? tab.name.toUpperCase() : tab.name}
                      </Typography>
                    </Link>
                  ))}
                </div>

                <div className="p-5">
                  <Outlet context={{contest, isPrivileged, groupContestId}} />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </Page>
  );
};

const getTabs = (isTeacher: boolean) => {
  const commonTabs = [
    {
      name: 'Problems',
      path: 'problems',
    },
    {
      name: 'Details',
      path: 'details',
    },
    {
      name: 'Leaderboard',
      path: 'leaderboard',
    },
  ];

  if (isTeacher) {
    commonTabs.push({
      name: 'Statistics',
      path: 'statistics',
    });
    commonTabs.push({
      name: 'Submissions',
      path: 'submissions',
    });
  }

  if (!isTeacher) {
    commonTabs.push({
      name: 'My Submissions',
      path: 'my-submissions',
    });
  }
  return commonTabs;
};
