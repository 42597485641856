import {useState, useReducer, useEffect} from 'react';

import {Typography} from '@mui/material';

import {ButtonSize as Size, Color, InputProps, ButtonSize} from '../../../types';
import {File} from '../file/File';
import {updateProperty} from '../../../utils';
import {Button} from '../../button/Button';

export const Test = (props: InputProps) => {
  const [tests, setTests] = useState<Array<Record<string, any>>>(props.value ?? []);
  const [maxNoTestsLeft, setMaxNoTestsLeft] = useState(props.maxNoEntries ? props.maxNoEntries - tests.length : 0);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  useEffect(() => {
    if (props.onChange) {
      props.onChange(null, tests);
    }
  }, [tests]);

  const onChange = (testIdx: number) => (field: string, value: string) => {
    const newTests = [...tests];
    newTests[testIdx] = updateProperty(newTests[testIdx], field, value);
    setTests(newTests);
    if (props.onChange) {
      props.onChange(null, newTests);
    }
    forceUpdate();
  };

  const addNewTest = () => {
    setTests([
      ...tests,
      {
        grade: null,
        input: null,
        output: null,
      },
    ]);
    setMaxNoTestsLeft(maxNoTestsLeft - 1);
    forceUpdate();
  };

  const removeTest = (testIdx: number) => {
    const newTests = [...tests];
    newTests.splice(testIdx, 1);

    setTests(newTests);
    setMaxNoTestsLeft(maxNoTestsLeft + 1);
    forceUpdate();
  };

  const options: {name: string; label: string}[] = [
    {name: 'grade', label: 'grade'},
    {name: 'input', label: 'input'},
    {name: 'output', label: 'expected output'},
  ];

  return (
    <>
      <Typography variant="h6" sx={{marginBottom: '10px', marginTop: '10px'}}>
        {props.title}
      </Typography>
      {tests.map((test, testIndex) => (
        <div key={testIndex} className="flex justify-center content-center gap-2 mb-5 w-full">
          <div className="mr-3">
            <Button
              label={'X'}
              size={ButtonSize.SMALL}
              color={Color.PRIMARY}
              onClick={() => removeTest(testIndex)}
              style={{padding: '2px', minWidth: '30px'}}
            />
          </div>
          {options.map((option, optionIndex) => (
            <div key={`${testIndex}-${optionIndex}`} className="w-full">
              <Typography variant="subtitle2">{option.label.toLocaleUpperCase()}</Typography>
              <File
                fullWidth={true}
                id={option.name}
                onChange={onChange(testIndex)}
                placeholder={`# ${option} ${props.id}${testIndex + 1}`}
                rules={{}}
                value={test ? test[option.name] : null}
              />
            </div>
          ))}
        </div>
      ))}
      {maxNoTestsLeft !== undefined && maxNoTestsLeft > 0 && (
        <Button color={Color.GRID_PRIMARY} label={`Add ${props.id}`} onClick={addNewTest} size={Size.LARGE} />
      )}
    </>
  );
};
