import {useContext, useEffect, useState, useReducer} from 'react';

import {v4 as uuid} from 'uuid';
import {Typography} from '@mui/material';

import {sendGetRequest, URLs} from '../../../api';
import {ButtonSize, InputProps, Color} from '../../../types';
import {Dropdown} from '../dropdown/Dropdown';
import AppContext from '../../../context/AppContext';
import {updateProperty} from '../../../utils';
import {Button} from '../..';

export const Pair = (props: InputProps) => {
  const {dispatchError, user} = useContext(AppContext);

  const [pairs, setPairs] = useState<Array<Record<string, any>>>(
    props.value?.map((pair: any) => ({...pair, id: uuid()})) ?? []
  );

  const [problems, setProblems] = useState<Array<string>>([]);
  const [maxNoProblemsLeft, setMaxNoProblemsLeft] = useState(
    props.maxNoEntries ? props.maxNoEntries - pairs.length : 0
  );
  const [loadProblems, setLoadProblems] = useState(false);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const addNewProblem = () => {
    // The first problem available is selected by default
    const newPairs = [...pairs, {problem: problems[0], quota: null, id: uuid()}];
    setPairs(newPairs);
    setProblems(problems.slice(1));

    // Propagate the changes to the parent
    if (props.onChange) {
      props.onChange(null, newPairs);
    }
    setMaxNoProblemsLeft(maxNoProblemsLeft - 1);
    forceUpdate();
  };

  const removeProblem = (pairIdx: number) => {
    const newPairs = [...pairs];
    newPairs.splice(pairIdx, 1);
    setPairs(newPairs);

    // Propagate the changes to the parent
    if (props.onChange) {
      props.onChange(null, newPairs);
    }

    // Mark the old problem name as available
    const newProblems = [pairs[pairIdx].problem, ...problems];
    setProblems(newProblems);

    setMaxNoProblemsLeft(maxNoProblemsLeft + 1);
    forceUpdate();
  };

  const onChange = (pairIdx: number) => (event: {value: string; label: string}) => {
    // Mark the old problem name as available
    const newProblems = [pairs[pairIdx].problem, ...problems];
    // Remove the selected name from the remaining options
    newProblems.splice(newProblems.indexOf(event.value), 1);
    setProblems(newProblems);

    const newPairs = [...pairs];
    newPairs[pairIdx] = updateProperty(newPairs[pairIdx], 'problem', event.value);

    setPairs(newPairs);

    if (props.onChange) {
      props.onChange(null, newPairs);
    }
    forceUpdate();
  };

  useEffect(() => {
    if (loadProblems === false) {
      sendGetRequest(URLs.availableProblems, {
        //user_id: context.user!.id ?? '',
      })
        .then((response) => {
          const allProblems = response.data.map((problem: any) => problem.title);
          const alreadySelected = pairs.map((pair: any) => pair.problem);
          setProblems(allProblems.filter((name: string) => !alreadySelected.includes(name)));
          setLoadProblems(true);
        })
        .catch((err) => {
          if (err) {
            if (err.response && err.response.data && err.response.data.error) {
              dispatchError(err.response.data.error, '/');
            } else {
              dispatchError('There was an error. Please try again later', '/');
            }
          }
        });
    }
  }, [loadProblems, user, pairs]);

  return (
    <>
      <Typography variant="h6" sx={{margin: '10px 0 10px 0'}}>
        {props.title}
      </Typography>
      <div style={{display: 'flex', flexDirection: 'column', gap: '12px'}}>
        {pairs.map((pair, index) => (
          <div key={pair.id} style={{display: 'flex', justifyContent: 'space-between', gap: '12px'}}>
            <Dropdown
              fullWidth={props.fullWidth}
              id={`${props.id}-problem`}
              name="problem"
              onChange={onChange(index)}
              options={[pair.problem, ...problems]}
              rules={{}}
              value={pair.problem}
            />

            <Button label={'X'} size={ButtonSize.SMALL} color={Color.PRIMARY} onClick={() => removeProblem(index)} />
          </div>
        ))}
      </div>
      {maxNoProblemsLeft !== undefined && maxNoProblemsLeft > 0 && problems.length > 0 && (
        <Button
          className="mt-25"
          color={Color.GRID_PRIMARY}
          label="Add problem"
          onClick={addNewProblem}
          size={ButtonSize.LARGE}
        />
      )}
    </>
  );
};
