import {Button, Modal, Typography, useTheme} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

type ConfirmationModalProps = {
  message: string;
  onClose: () => void;
  onAccept: () => void;
};

function ConfirmationModal({message, onClose, onAccept}: ConfirmationModalProps) {
  const theme = useTheme();

  const modalStyle: React.CSSProperties = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    height: 'auto',
    backgroundColor: theme.palette.background.default,
  };

  return (
    <>
      <Modal open={true} onClose={onClose}>
        <div className="flex flex-col justify-around items-center rounded p-3 gap-5" style={modalStyle}>
          <Typography my={2} className="text-center" color={theme.palette.primary.contrastText} variant="h4">
            {message}
          </Typography>
          <div className="flex flex-row justify-center my-1 gap-4 items-center w-full">
            <Button variant="outlined" onClick={() => onAccept()} startIcon={<DeleteIcon />}>
              Delete
            </Button>

            <Button
              variant="contained"
              onClick={onClose}
              sx={{
                '&.MuiButton-root': {
                  marginLeft: '8px',
                  background: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                },
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ConfirmationModal;
