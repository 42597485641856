import {useContext} from 'react';

import AppContext from '../../context/AppContext';
import {GenericMenu} from './generic-menu/GenericMenu';
import {ButtonSize as Size, Color, MenuProps, Role} from '../../types';

export const roleBasedProps = (role: undefined | Role, logoutCallback: () => void) => {
  if (role) {
    const staffTabs =
      role === Role.ADMIN
        ? [
            {text: 'Staff', to: '/staff/users'},
            {text: 'Problems', to: '/problems'},
          ]
        : [];

    const teacherTabs =
      role === Role.TEACHER
        ? [
            {text: 'Staff', to: '/staff/curriculum'},
            {text: 'Problems', to: '/problems'},
          ]
        : [];

    const creatorTabs =
      role === Role.CREATOR
        ? [
            {text: 'Staff', to: '/staff/categories'},
            {text: 'Problems', to: '/problems'},
          ]
        : [];

    return {
      logo: '/contests',
      links: [
        {text: 'Profile', to: '/profile'},
        {text: 'Contests', to: '/contests'},
        ...staffTabs,
        ...teacherTabs,
        ...creatorTabs,
        // TODO: re-enable after fixing the route
        // { text: 'Leaderboard', to: '/leaderboard' },
      ],
      buttons: [
        {
          color: Color.PRIMARY,
          onClick: logoutCallback,
          text: 'Log out',
          size: Size.MEDIUM,
        },
      ],
    };
  } else {
    return {
      logo: '/',
      links: [
        //{ text: "About", to: "/about" },
        //{ text: "Discover", to: "/discover" },
        {text: 'Contact', to: '/contact'},
      ],
      buttons: [
        {
          color: Color.SECONDARY,
          link: '/login',
          text: 'Log in',
          size: Size.MEDIUM,
        },
        {
          color: Color.PRIMARY,
          link: '/register',
          text: 'Register',
          size: Size.MEDIUM,
        },
      ],
    };
  }
};

/**
 * Horizontal menu with custom options based on the authentication status of the user.
 */
export const Menu = (props: MenuProps) => {
  const context = useContext(AppContext);

  return (
    <>
      <GenericMenu width={props.width} {...roleBasedProps(props.role, context.logoutCallback)} />
    </>
  );
};
