import {Category} from './Category';
import {Test} from './Test';

export class Problem {
  categories?: Array<Category>;
  description: string;
  difficulty: string;
  example_id: number;
  id: number;
  language_id: number;
  title: string;
  skeleton: string;
  tests: Array<Test>;
  visible: boolean;
  created_by: number;

  constructor(data: any) {
    this.categories = data.categories;
    this.description = data.description;
    this.difficulty = data.difficulty;
    this.example_id = data.example_id;
    this.id = data.id;
    this.language_id = data.language_id;
    this.title = data.title;
    this.skeleton = data.skeleton;
    this.tests = (data.tests ?? []).map((test: Test) => new Test(test));
    this.visible = data.visible;
    this.created_by = data.created_by;
  }
}
