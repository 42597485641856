import {Role} from '../types';

export const rolesMapping = (userRoleCode: number): Role => rolesMap[userRoleCode];

const rolesMap: {[key: number]: Role} = {
  1: Role.STUDENT,
  2: Role.TEACHER,
  3: Role.ADMIN,
  4: Role.CREATOR,
  5: Role.GUEST,
};

export const fromRoleNumberToString = (role: number): string => {
  switch (role) {
    case 1:
      return 'STUDENT';
    case 2:
      return 'TEACHER';
    case 3:
      return 'ADMIN';
    case 4:
      return 'CREATOR';
    default:
      return 'UNKNOWN';
  }
};
