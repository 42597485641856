import {ReactElement, useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router';
import {useOutletContext} from 'react-router-dom';

import {Skeleton, Typography, TableBody, TableRow, TableCell, Table as MuiTable, Tooltip} from '@mui/material';
import clsx from 'clsx';

import {ContestContextType} from './Contest';
import AppContext from '../../context/AppContext';
import {usePage} from '../../hooks';
import {Pagination} from '../../components/index';
import {stringsToColoredChips, toChips} from '../../components/problems-table/Chips';
import SubmissionStatus from '../../components/contest-card/utils/SubmissionStatus';
import {ContestViewLastSubmissionStats, ContestViewProblemData} from '../../api/responses/ContestViewResponseTypes';

export const ContestProblems = () => {
  const pageSize = 6;

  const {contest, groupContestId} = useOutletContext<ContestContextType>();
  const context = useContext(AppContext);

  const [problems, setProblems] = useState<ProblemEntry[] | null>(null);
  const {page, changePage} = usePage();

  const navigate = useNavigate();

  useEffect(() => {
    if (!contest?.problems || (page - 1) * pageSize >= contest.problems.length) {
      setProblems(null);
    } else {
      const problemPage: ContestViewProblemData[] = contest.problems.slice((page - 1) * pageSize, page * pageSize);

      setProblems(
        problemPage.map((problem: ContestViewProblemData) => {
          const hasLastSubmission = JSON.stringify(problem.lastSubmission) !== '{}';
          const isCompiled = hasLastSubmission
            ? (problem.lastSubmission as ContestViewLastSubmissionStats).compiled
            : false;
          const isMaxGrade = hasLastSubmission
            ? (problem.lastSubmission as ContestViewLastSubmissionStats).maxGrade
            : false;

          return {
            id: problem.id,
            name: <Typography variant="subtitle1">{problem.title}</Typography>,
            status: hasLastSubmission ? <SubmissionStatus compiled={isCompiled} maxGrade={isMaxGrade} /> : <></>,
            language: toChips('language', [context.languages[problem.language_id]]),
            difficulty: toChips('difficulty', [problem.difficulty]),
            categories:
              problem.categories?.length > 0
                ? stringsToColoredChips(
                    problem.categories.map((category) => category.title),
                    true
                  )
                : stringsToColoredChips(['Uncategorized']),
            isCompleted: isCompiled && isMaxGrade,
            maxScore: (
              <Tooltip arrow title="Max Score">
                <Typography variant="subtitle2">{toChips('', [problem.maxScore.toLocaleString()])}</Typography>
              </Tooltip>
            ),
            successRate: (
              <Tooltip
                arrow
                title={
                  <span className="flex text-center whitespace-pre-line">
                    {`Success Rate \n Solved: ${problem.successRate.solved} \n Attempted: ${problem.successRate.attempts}`}
                  </span>
                }
              >
                <Typography variant="subtitle2">
                  {toChips('', [
                    problem.successRate.attempts !== 0
                      ? ((problem.successRate.solved / problem.successRate.attempts) * 100).toFixed(2) + '%'
                      : 'No attempts',
                  ])}
                </Typography>
              </Tooltip>
            ),
          };
        })
      );
    }
  }, [page, contest?.problems]);

  const loadProblem = (problemId: number) => (_event: any) => {
    navigate(`/problem/${problemId}/${contest?.id}/${groupContestId}`);
  };

  return (
    <div
      className={clsx('min-h-[55vh] flex flex-col', {
        'justify-center': problems,
      })}
    >
      {!problems && <Skeleton animation="pulse" variant="rectangular" className="h-[55vh] w-full" />}
      {problems && problems.length === 0 && (
        <>
          <Typography align="center" variant="h3" className="pt-5">
            Looks like the contest is not ready yet.
          </Typography>
          <Typography align="center" variant="h5">
            The problems will be published here soon 🚀
          </Typography>
        </>
      )}

      {problems && problems.length > 0 && (
        <div className="flex flex-col justify-between min-h-[55vh] px-8">
          <MuiTable className="min-w-[90%]">
            <TableBody>
              {problems?.map((problem) => (
                <TableRow
                  key={problem.id}
                  onClick={loadProblem(problem.id)}
                  className="cursor-pointer"
                  sx={{
                    '&:last-child td, &:last-child th': {border: 0},
                    backgroundColor: problem.isCompleted ? '#305746' : '#303A57',
                    '&:hover': {
                      backgroundColor: problem.isCompleted ? '#3C6B57' : '#424E6A',
                    },
                  }}
                >
                  <TableCell align="center" key={`${problem.id}-name`} className="text-white">
                    {problem.name}
                  </TableCell>
                  <TableCell align="center" key={`${problem.id}-status`} className="text-white">
                    {problem.status}
                  </TableCell>
                  <TableCell align="center" key={`${problem.id}-language`} className="text-white">
                    {problem.language}
                  </TableCell>
                  <TableCell align="center" key={`${problem.id}-difficulty`} className="text-white">
                    {problem.difficulty}
                  </TableCell>

                  <TableCell align="center" key={`${problem.id}-max-score`} className="text-white">
                    {problem.maxScore}
                  </TableCell>

                  <TableCell align="center" key={`${problem.id}-success-rate`} className="text-white">
                    {problem.successRate}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </MuiTable>

          <div>
            <Pagination count={Math.ceil(contest!.problems.length / pageSize)} page={page} onChange={changePage} />
          </div>
        </div>
      )}
    </div>
  );
};

type ProblemEntry = {
  id: number;
  name: ReactElement;
  status: ReactElement;
  language: ReactElement[];
  difficulty: ReactElement[];
  isCompleted: boolean;
  categories?: ReactElement[];
  maxScore: ReactElement;
  successRate: ReactElement;
};
