import {useMemo, useState} from 'react';
import Select, {StylesConfig} from 'react-select';

import {useTheme} from '@mui/material';

import {InputProps as Props} from '../../../types';

export const dropdownStylesConfig = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const theme = useTheme();

  const dropdownStyles: StylesConfig = {
    container: (styles) => ({
      ...styles,
      width: '100%',
    }),

    control: (styles) => ({
      ...styles,
      backgroundColor: theme.palette.inputBox.backgroundColor,
      border: 0,
      minHeight: '56px',
    }),

    option: (styles, {isFocused, isSelected}) => ({
      ...styles,
      color: 'white',
      backgroundColor: isFocused
        ? theme.palette.selectBox.option.focusBackgroundColor
        : isSelected
        ? theme.palette.selectBox.option.selectedBackgroundColor
        : theme.palette.selectBox.option.backgroundColor,
    }),

    input: (styles) => ({
      ...styles,
      color: 'white',
    }),

    placeholder: (styles) => ({
      ...styles,
      color: 'white',
    }),

    singleValue: (styles) => ({
      ...styles,
      color: 'white',
    }),

    menuList: (styles) => ({
      ...styles,
      backgroundColor: theme.palette.selectBox.option.backgroundColor,
    }),
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: theme.palette.selectBox.option.selectedBackgroundColor,
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      color: 'white',
    }),
    multiValueRemove: (styles) => ({
      ...styles,

      ':hover': {
        backgroundColor: '#602E2E',
        color: '#DB6E78',
      },
    }),
  };

  return dropdownStyles;
};

export const Dropdown = (props: Props) => {
  const [selected, setSelected] = useState<{label: string; value: string}>({label: props.value, value: props.value});

  const handleChange = (e: {value: string; label: string}) => {
    setSelected(e);
    if (props.onChange) {
      props.onChange(e);
    }
  };

  const options = useMemo(() => props.options?.map((option) => ({value: option, label: option})), [props.options]);

  const dropdownStyles = dropdownStylesConfig();

  return (
    <Select
      options={options}
      defaultValue={selected}
      styles={dropdownStyles}
      placeholder={props.placeholder}
      onChange={(newValue, _) => handleChange(newValue as {value: string; label: string})}
    />
  );
};

export const MultiDropdown = (props: Props) => {
  const [selected, setSelected] = useState<Array<{label: string; value: number}>>(props.selectedOptions ?? []);

  const handleChange = (e: Array<{value: number; label: string}>) => {
    setSelected(e);
    if (props.onChange) {
      props.onChange(e);
    }
  };

  const dropdownStyles = dropdownStylesConfig();

  return (
    <Select
      isMulti
      closeMenuOnSelect={false}
      options={props.options}
      defaultValue={selected}
      styles={dropdownStyles}
      placeholder={props.placeholder}
      onChange={(newValue, _) => handleChange(newValue as Array<{value: number; label: string}>)}
    />
  );
};
