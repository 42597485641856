import axios, {AxiosError, AxiosRequestHeaders, AxiosResponse} from 'axios';

// TODO: Move it to env var
export const BASE_URL =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://api.devmind.ro/';

const API = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export type URLsType = {
  allContests: string;
  allContestsInActiveGroups: string;
  availableProblems: string;
  contestLeaderboard: (contestId: number, groupContestId: number, arrangements: string) => string;
  createContest: string;
  createProblem: string;
  createSubmission: (problemId: number, contestId?: number, groupContestId?: number) => string;
  enrollmentStatus: string;
  enrollToContest: string;
  enrollToContestNew: (contestId: number) => string;
  generalLeaderboard: string;
  getContest: string;
  getDefaultSkeleton: string;
  getWrapper: string;
  getOwnedContests: string;
  getOwnedProblems: string;
  getProblem: string;
  getSubmissions: (problemId: number) => string;
  login: string;
  register: string;
  resetPassword1: string;
  resetPassword2: string;
  updateContest: string;
  updateProblem: string;
  updateUser: string;
  user: (userId: number) => string;
  pendingUsers: string;
  activeUsers: (arrangaments: string) => string;
  archivedUsers: (arrangaments: string) => string;
  deletePendingUser: string;
  changeUserStatus: string;
  getContestSubmissions: (contestId: number) => string;
  getMakeContestSubmissions: (contestId: number) => string;
  getMyContestSubmissions: (contestId: number) => string;
  myGroups: string;
  getUsersInGroup: (groupId: number) => string;
  accessibleUserGroups: string;
  assignUserToGroup: string;
  unassignUserFromGroup: string;
  createGroup: string;
  groupProgress: (groupId: number) => string;
  availableContests: (moduleId: number, groupId: number) => string;
  assignContestToGroup: string;
  editContestToGroup: string;
  getAllModules: string;
  updateUserSettings: (userId: number) => string;
  updateGroup: (groupId: number) => string;
  deleteGroup: (groupId: number) => string;
  findUsersByEmail: (emailQuery: string) => string;
  paginatedProblems: (filters: string) => string;
  unassignGroupContestFromGroup: string;
  getAllCategories: string;
  deleteCategory: (categoryId: number) => string;
  updateCategory: (categoryId: number) => string;
  createCategory: string;
  importUsers: string;
  getSubjectCoverage: (groupId: number) => string;
  getSuccessRate: (groupId: number, userId: number) => string;
  getSubmissionsActivity: (groupId: number, userId: number) => string;
  getUsersManagementNotifications: string;
  eradicateStudent: (studentId: number) => string;
  getContestTemplates: string;
  getContestTemplate: (teamplateId: number) => string;
};

export const URLs: URLsType = {
  allContests: '/contests',
  allContestsInActiveGroups: '/contests-active-groups',
  availableProblems: '/problems',
  contestLeaderboard: (contestId: number, groupContestId: number, arrangements: string) =>
    `/contests/${contestId}/rankings?groupContestId=${groupContestId}&${arrangements}`,
  createContest: '/contests',
  createProblem: '/problems',
  createSubmission: (problemId: number, contestId?: number, groupContestId?: number) =>
    `/problems/${problemId}/submit${contestId ? '?contest_id=' + contestId : ''}${
      contestId ? '&group_contest_id=' + groupContestId : ''
    }`,
  enrollmentStatus: '/enroll_participants/is_enrolled',
  enrollToContest: '/enroll_participants',
  enrollToContestNew: (contestId: number) => `/contests/${contestId}/enroll`,
  generalLeaderboard: '/public-ranking',
  getContest: '/contests',
  getDefaultSkeleton: '/sources/get_default_skeleton',
  getWrapper: '/sources/get_wrapper',
  getOwnedContests: '/contests/show_ownership/',
  getOwnedProblems: '/problems/show_ownership/',
  getProblem: '/problems',
  getSubmissions: (problemId: number) => `/problems/${problemId}/my-submissions`,
  login: '/login',
  register: '/register',
  resetPassword1: '/reset-password-1',
  resetPassword2: '/reset-password-2',
  updateContest: '/contests',
  updateProblem: '/problems',
  updateUser: '/users', // TODO: use /user/:userId
  user: (userId: number) => `/users/${userId}`,
  pendingUsers: '/users/pending',
  activeUsers: (arrangaments: string) => `/users/paginated?${arrangaments}&status=active`,
  archivedUsers: (arrangaments: string) => `/users/paginated?${arrangaments}&status=archived`,
  deletePendingUser: '/users/pending/delete',
  changeUserStatus: '/users/change-status',
  getContestSubmissions: (contestId: number) => `/contest/${contestId}/submissions`,
  getMakeContestSubmissions: (contestId: number) => `/contest/${contestId}/all-submissions`,
  getMyContestSubmissions: (contestId: number) => `/contest/${contestId}/my-submissions`,
  myGroups: '/groups/my-groups',
  getUsersInGroup: (groupId: number) => `/groups/${groupId}/users`,
  accessibleUserGroups: '/groups',
  assignUserToGroup: '/groups/assign-user',
  unassignUserFromGroup: '/groups/unassign-user',
  createGroup: '/groups/create',
  groupProgress: (groupId: number) => `/groups/${groupId}/progress`,
  availableContests: (moduleId: number, groupId: number) => `/contests/available/${moduleId}/${groupId}`,
  assignContestToGroup: '/contests/group-assign',
  editContestToGroup: '/contests/edit-group-assign',
  getAllModules: '/modules/all',
  updateUserSettings: (userId: number) => `/users/${userId}/settings`,
  updateGroup: (groupId: number) => `/groups/${groupId}/update`,
  deleteGroup: (groupId: number) => `/groups/${groupId}`,
  findUsersByEmail: (emailQuery: string) => `users/find?email=${emailQuery}`,
  paginatedProblems: (filters: string) => `problems/paginated?${filters}`,
  unassignGroupContestFromGroup: 'contests/group-unassign',
  getAllCategories: '/category/all',
  deleteCategory: (categoryId: number) => `/category/${categoryId}`,
  updateCategory: (categoryId: number) => `/category/${categoryId}`,
  createCategory: '/category',
  importUsers: '/users/import',
  getSubjectCoverage: (groupId: number) => `/statistics/subject-covered?groupId=${Number(groupId)}`,
  getSuccessRate: (groupId: number, userId: number) =>
    `/statistics/success-rate?groupId=${Number(groupId)}&userId=${Number(userId)}`,
  getSubmissionsActivity: (groupId: number, userId: number) =>
    `/statistics/submissions-activity?groupId=${Number(groupId)}&userId=${Number(userId)}`,
  getUsersManagementNotifications: '/staff/users-management/notifications',
  eradicateStudent: (studentId: number) => `/users/eradicate/${studentId}`,
  getContestTemplates: '/contest-templates/all',
  getContestTemplate: (templateId: number) => `/contest-templates/${templateId}`,
};

export const setAuthorizationHeader = (token: string) => {
  API.defaults.headers.common['Accept'] = 'json';
  API.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

export const sendGetRequest = (url: string, data?: any) => API.get(url, data);

export const sendPostRequest = (url: string, data?: any) =>
  API.post(url, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const sendDeleteRequest = (url: string, data?: any) =>
  API.delete(url, {
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  });

export const sendPutRequest = (url: string, data: any) => API.put(url, data);
export const sendPatchRequest = (url: string, data: any) => API.patch(url, data);

export const sendRequest = (method: string, url: string, data: any) => {
  switch (method) {
    case 'get':
      return sendGetRequest(url, data);
    case 'post':
      return sendPostRequest(url, data);
    default:
      return sendPutRequest(url, data);
  }
};

export const request = async <
  RequestParamsType extends Record<string, string | number | string>,
  RequestBodyType,
  RequestResponseType,
>(
  API_URL: string,
  details: RequestDetails<RequestParamsType, RequestBodyType, RequestResponseType>
): Promise<void> => {
  let fullURL;
  if (details.params) {
    const keys = Object.keys(details.params);
    // eslint-disable-next-line prefer-const
    fullURL = `${API_URL}?${keys.map((key) => `${key}=${details.params?.[key]}`).join('&')}`;
  }

  try {
    let response: AxiosResponse;
    switch (details.method) {
      case 'GET':
        response = await API.get(fullURL ?? API_URL, {headers: details.headers});
        break;
      case 'POST':
        response = await API.post(fullURL ?? API_URL, details.body, {headers: details.headers});
        break;
      case 'PUT':
        response = await API.put(fullURL ?? API_URL, details.body, {headers: details.headers});
        break;
      case 'DELETE':
        response = await API.delete(fullURL ?? API_URL, {headers: details.headers});
        break;
    }

    details.successCallback(response?.data);
  } catch (error: unknown) {
    details.errorCallback(error as Error | AxiosError);
  }
};

type RequestDetails<ParamsType, BodyType, ResponseType> = {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  params?: ParamsType;
  headers?: AxiosRequestHeaders;
  successCallback: (response: ResponseType) => void;
  errorCallback: (error: Error) => void;
} & ({method: 'POST' | 'PUT' | 'DELETE'; body?: BodyType} | {method: 'GET'; body?: never});
