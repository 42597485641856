import {useEffect, useMemo, useState} from 'react';
import Select from 'react-select';

import {Button, Modal, Typography, useTheme} from '@mui/material';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {DesktopDatePicker} from '@mui/x-date-pickers';

import {sendGetRequest, sendPostRequest, URLs} from '../../../api';
import {CurriculumContest} from '../../../api/responses/CurriculumResponseTypes';
import {AssignContestToGroupRequest} from '../../../api/Requests';
import {Color} from '../../../types';
import {dropdownStylesConfig} from '../../../components/form/dropdown/Dropdown';
import {DateTimeTextField} from '../../../components/form/datetime-textfield/DateTimeTextField';

export type GroupCurriculaEditingModalProps = {
  groupName: string;
  groupId: number;
  moduleId: number;
  onClose: () => void;
  handleHttpError: (err: any) => void;
  onSuccess: () => void;
};
export const GroupCurriculaEditingModal = ({
  groupName,
  groupId,
  moduleId,
  onClose,
  handleHttpError,
  onSuccess,
}: GroupCurriculaEditingModalProps) => {
  const theme = useTheme();

  const [contests, setContests] = useState<Array<CurriculumContest> | null>(null);
  const [selectedContest, setSelectedContest] = useState<CurriculumContest | null>(null);

  const [startDate, setStartDate] = useState(new Date(Date.now()));
  const [endDate, setEndDate] = useState(new Date(Date.now()));
  const [message, setMessage] = useState('');

  const getAvailableContests = () => {
    sendGetRequest(URLs.availableContests(moduleId, groupId))
      .then((response) => {
        setContests(response.data.contests);
      })
      .catch((err) => {
        if (err) {
          handleHttpError(err);
        }
      });
  };

  useEffect(() => {
    getAvailableContests();
  }, []);

  const options = useMemo(() => contests?.map((contest) => ({label: contest.title, value: contest.id})), [contests]);

  const onAssignPressed = () => {
    if (selectedContest != null) {
      const requestPayload: AssignContestToGroupRequest = {groupId, contestId: selectedContest.id, startDate, endDate};

      sendPostRequest(URLs.assignContestToGroup, requestPayload)
        .then((_response) => {
          setMessage('Contest assigned successfully. Check group curriculum section for more details.');
          getAvailableContests();
          setSelectedContest(null);
          onSuccess();
        })
        .catch((err) => {
          if (err) {
            handleHttpError(err);
          }
        });
    }
  };

  const dropdownStyles = dropdownStylesConfig();

  return (
    <Modal open={true} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <div className="bg-background-default p-12 h-fit w-[60%] rounded-xl absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 outline-none">
        <div className="flex flex-col items-center justify-around ">
          <Typography
            color={theme.palette.secondary.light}
            align="center"
            variant="subtitle1"
          >{`Enable a new contest for the selected group: ${groupName}`}</Typography>

          <div className="flex flex-col items-center justify-evenly gap-4 w-full my-12">
            <div className="flex w-full gap-4">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="Start Date"
                  inputFormat="MM/dd/yyyy"
                  value={startDate}
                  onChange={(date) => {
                    if (date) {
                      setStartDate(date);
                    }
                  }}
                  renderInput={(params) => <DateTimeTextField {...params} />}
                />
                <DesktopDatePicker
                  label="End Date"
                  inputFormat="MM/dd/yyyy"
                  value={endDate}
                  onChange={(date) => {
                    if (date) {
                      setEndDate(date);
                    }
                  }}
                  renderInput={(params) => <DateTimeTextField {...params} />}
                />
              </LocalizationProvider>
            </div>

            <div className="flex w-full">
              {contests && (
                <Select
                  id="select-contest"
                  options={options}
                  defaultValue={selectedContest?.title}
                  onChange={(event: unknown) => {
                    const selectedId = (event as {value: string; label: string}).value;
                    const contest = contests.filter((con) => con.id === Number(selectedId))[0];
                    setSelectedContest(contest);
                  }}
                  styles={dropdownStyles}
                />
              )}
            </div>
          </div>
          <Button
            color={Color.SECONDARY}
            variant="contained"
            size="small"
            onClick={() => onAssignPressed()}
            disabled={groupId === undefined || !selectedContest}
            sx={{
              '&.Mui-disabled': {
                background: theme.palette.primary.dark,
                color: theme.palette.gridPrimary.contrastText,
              },
            }}
          >
            Add Contest
          </Button>
          {message !== '' && (
            <Typography my={2} align="center" color={theme.palette.secondary.light} variant="subtitle1">
              {message}
            </Typography>
          )}
        </div>
      </div>
    </Modal>
  );
};
