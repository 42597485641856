import {useContext} from 'react';

import AppContext from '../../../context/AppContext';
import {ButtonSize as Size, Color, EnrollmentStatus} from '../../../types';
import {sendPostRequest, URLs} from '../../../api';
import {NonParticipantV2Props} from '../../../components/contests-grid/card/Card.props';
import {Button} from '../../../components';

export const NonParticipantV2 = (props: NonParticipantV2Props) => {
  const {dispatchError} = useContext(AppContext);

  const handleSubmit = () => {
    sendPostRequest(URLs.enrollToContestNew(Number(props.contest.id)))
      .then((response) => {
        if (response.data.status !== 'Failed') {
          props.updateStatus(EnrollmentStatus.ENROLLED, props.contest);
        }
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.data && err.response.data.error) {
            dispatchError(err.response.data.error, '/');
          } else {
            dispatchError('There was an error. Please try again later', '/');
          }
        }
      });
  };

  const disabled = new Date(props.contest.contest_start_date) > new Date(Date.now());

  return (
    <Button
      className="mt-10"
      color={Color.SECONDARY}
      label="Join"
      onClick={handleSubmit}
      size={Size.SMALL}
      variant={disabled ? 'outlined' : 'contained'}
      disabled={disabled}
    />
  );
};
