export class Test {
  grade: string;
  input: string;
  output: string;
  id: number;

  constructor(data: any) {
    this.grade = `${data.grade}`;
    this.input = data.input;
    this.output = data.output;
    this.id = data.id;
  }

  public toObject(): object {
    return {
      grade: this.grade,
      input: this.input,
      output: this.output,
      id: this.id,
    };
  }
}
