import {AddCircle} from '@mui/icons-material';

import {ModalWithButton as Modal, Shape} from '../../components';
import {Position} from '../../types';
import {FloatingButtonProps} from './FloatingButton.props';

export const FloatingButton = (props: FloatingButtonProps) => (
  <Shape isAbove={true} paddingLeft={10} paddingTop={-50} position={Position.BOTTOM_RIGHT} width={70}>
    <Modal
      button={{
        element: <AddCircle sx={{cursor: 'pointer', fontSize: '40px'}} />,
      }}
      children={props.children}
      className="allowScrollbar"
      closeModal={props.toggleOpen}
      height={window.innerHeight * 0.9}
      open={props.open}
      openModal={props.toggleOpen}
      overflow="scroll"
      width={window.innerWidth * 0.75}
    />
  </Shape>
);
