import {ReactNode, useContext} from 'react';
import {NavLink, Outlet} from 'react-router-dom';

import {Typography} from '@mui/material';
import {PersonPin, Settings, AccountBox} from '@mui/icons-material';

import AppContext from '../../context/AppContext';
import {Page} from '../../components';

export const Profile = () => {
  const {dispatchError} = useContext(AppContext);

  const handleHttpError = (err: any) => {
    if (err.response && err.response.data && err.response.data.error) {
      dispatchError(err.response.data.error, '/contests');
    } else {
      dispatchError('There was an error. Please try again later', '/contests');
    }
  };

  return (
    <Page>
      <div className="flex items-center justify-center w-full">
        <div className="grid grid-cols-12 gap-5 rounded-2xl w-full">
          <aside className="col-span-4 bg-card-main rounded-2xl h-[80vh] sticky top-[84px]">
            <nav className="px-6 py-4">
              {profileTabs.map((tab) => (
                <NavLink
                  key={`${tab.name}`}
                  to={tab.path}
                  replace={true}
                  end
                  className="flex items-center rounded-lg h-12 px-4 mb-2 no-underline"
                  style={({isActive}) => ({
                    backgroundColor: isActive ? '#16a137' : 'inherit',
                  })}
                >
                  {tab.icon}
                  <Typography variant="body1">{tab.name}</Typography>
                </NavLink>
              ))}
            </nav>
          </aside>

          <div className="col-span-8 bg-card-main rounded-2xl w-full overflow-y-scroll">
            <div className="px-6 py-4 w-full">
              <Outlet context={{handleHttpError}} />
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

const profileTabs: {name: string; path: string; icon: ReactNode}[] = [
  {
    name: 'Profile',
    path: '',
    icon: <AccountBox fontSize="large" className="text-white mr-2" />,
  },
  {name: 'My account', path: 'account', icon: <PersonPin fontSize="large" className="text-white mr-2" />},
  {
    name: 'Settings',
    path: 'settings',
    icon: <Settings fontSize="large" className="text-white mr-2" />,
  },
];
