import {Table as MuiTable, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';

import {TableProps as Props} from '../../types';

const Container = styled(TableContainer)(() => ({
  minHeight: '100%',
  padding: '10px 30px 0 30px',
}));

const Cell = styled(TableCell)(() => ({
  padding: '14px',
}));

export const Table = (props: Props) => {
  const rows = props.rows ?? [];
  const columns =
    // Hardcoded to show header even if !rows
    // rows.length > 0
    //   ? Object.keys(props.rows[0]).filter((key) => key !== 'id')
    //   : []
    ['name', 'language', 'difficulty', 'category'];

  return (
    <Container>
      <MuiTable sx={{minWidth: '100%'}} aria-label="simple table">
        {!props.hideHeader && (
          <TableHead>
            <TableRow>
              {columns.map((column: string, key: number) => (
                <Cell align="center" key={`${key}-${column}`}>
                  <Typography variant="h6">{column.toUpperCase()}</Typography>
                </Cell>
              ))}
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {rows.map((row, index) => (
            <TableRow
              key={index}
              onClick={props.onClick(row.id)}
              sx={{
                '&:last-child td, &:last-child th': {border: 0},
                cursor: 'pointer',
              }}
            >
              {columns.map((column: string, key: number) => (
                <Cell align="center" className={props.cellClassName} key={`${key}-${column}`} sx={{color: 'white'}}>
                  {row[column]}
                </Cell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </MuiTable>
      {!rows ||
        (rows.length === 0 && (
          <Typography align="center" variant="h5" sx={{paddingTop: '20px'}}>
            No data to be displayed...
          </Typography>
        ))}
    </Container>
  );
};
