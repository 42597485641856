import React from 'react';

import {Modal as MuiModal} from '@mui/material';
import {useTheme} from '@mui/system';

import {ModalWithButtonProps as Props} from './Modal.props';
import {Button, Ellipse} from '../..';
import {ButtonSize, Color, EllipseSize as Size, Position} from '../../../types';

export const ModalWithButton = (props: Props) => {
  const theme = useTheme();
  const makeButton = () => {
    if (props.button.element) {
      return React.cloneElement(props.button.element, {
        className: 'mt-10',
        onClick: props.openModal,
      });
    }
    return (
      <Button
        className="mt-10"
        color={props.button.color ?? Color.PRIMARY}
        label={props.button.label!}
        onClick={props.openModal}
        variant={props.button.variant}
        size={ButtonSize.LARGE}
      />
    );
  };

  return (
    <>
      {makeButton()}
      <MuiModal open={props.open} onClose={props.closeModal}>
        <Ellipse
          backgroundColor={theme.palette.background.default}
          bounds={{position: Position.MIDDLE_CENTER}}
          children={props.children}
          className={props.className}
          height={props.height}
          overflow={props.overflow}
          size={Size.FULL}
          radius={10}
          width={props.width}
        />
      </MuiModal>
    </>
  );
};
