import {Checkbox as CheckboxMUI, Typography} from '@mui/material';

import {InputProps as Props} from '../../../types';
import {Error} from '../error/Error';

export const Checkbox = (props: Props) => {
  const error = props.error ?? false;

  const checked = typeof props.value === 'boolean' ? props.value : false;

  return (
    <div className={props.className}>
      <div className="flex flex-col">
        <div className="flex flex-row items-center">
          <CheckboxMUI checked={checked} onChange={props.onChange} name={props.name} />
          {error !== false && <Error id={props.id} message={error} />}

          <Typography variant="subtitle1">{props.title}</Typography>
        </div>
        {props.subTitle && (
          <Typography className="pl-2" color={'GrayText'} variant="body1">
            {props.subTitle}
          </Typography>
        )}
      </div>
    </div>
  );
};
