import {useEffect, useReducer, useState} from 'react';
import {useOutletContext} from 'react-router-dom';

import {Chip} from '@mui/material';

import {URLs, sendGetRequest} from '../../../api';
import {StaffPageOutletProps} from '../StaffPage';
import {ContestsViewContest, ContestsViewResponse} from '../../../api/responses/ContestsViewResponseTypes';
import {ContestsTemplatesTable} from './ContestTemplatesTable';
import {ModuleType} from '../../../types/entities/ModuleType';
import {ModalWithButton, NewContestForm} from '../../../components';
import {ContestViewData, ContestViewResponse} from '../../../api/responses/ContestViewResponseTypes';

export const ContestTemplates = () => {
  const {handleHttpError} = useOutletContext<StaffPageOutletProps>();

  // Workaround to not query the server each time we filter
  const [initialTemplates, setInitialTemplates] = useState<Array<ContestsViewContest>>([]);
  const [templates, setTemplates] = useState<Array<ContestsViewContest>>([]);
  const [modules, setModules] = useState<{[key: number]: any}>({});
  const [selectedTemplate, setSelectedTemplate] = useState<ContestViewData | undefined>(undefined);
  const [update, forceUpdate] = useReducer((x) => x + 1, 0);
  const [filteredModules, setFilteredModules] = useState<string[]>([]);

  const getContestTemplates = async () => {
    sendGetRequest(URLs.getContestTemplates)
      .then((response) => {
        const resonseTemplates: ContestsViewResponse = response.data;
        setTemplates(resonseTemplates.contests);
        setInitialTemplates(resonseTemplates.contests);
      })
      .catch((err) => {
        if (err) {
          handleHttpError(err);
        }
      });
  };

  const getModules = async () => {
    sendGetRequest(URLs.getAllModules)
      .then((response) => {
        const modules: Array<ModuleType> = response.data;
        const modulesMap: {[key: number]: any} = {};
        modules.forEach((module) => {
          module.id && (modulesMap[module.id] = module.module_name);
        });
        setModules(modulesMap);
        setFilteredModules(Object.keys(modulesMap));
      })
      .catch((err) => {
        if (err) {
          handleHttpError(err);
        }
      });
  };

  useEffect(() => {
    getContestTemplates();
    getModules();
  }, [update]);

  const onTemplateSelected = (templateId: number) => {
    sendGetRequest(`${URLs.getContestTemplate(templateId)}`)
      .then((response) => {
        const templateData: ContestViewResponse = response.data.template;
        setSelectedTemplate(templateData);
      })
      .catch((err) => {
        if (err) {
          if (err) {
            handleHttpError(err);
          }
        }
      });
  };

  useEffect(() => {
    setTemplates(initialTemplates.filter((template) => filteredModules.includes(String(template.module_id))));
  }, [filteredModules]);

  return (
    <div>
      {Object.keys(modules).length && (
        <div className="flex">
          {Object.keys(modules).map((moduleId) => {
            const isSelected = filteredModules.includes(moduleId);
            return (
              <div className="mr-2">
                <Chip
                  size="small"
                  label={modules[Number(moduleId)]}
                  onClick={() => {
                    if (!isSelected) {
                      setFilteredModules([...filteredModules, moduleId]);
                    }
                  }}
                  onDelete={() => {
                    if (isSelected) {
                      setFilteredModules(filteredModules.filter((module) => module !== moduleId));
                    }
                  }}
                  variant={isSelected ? 'filled' : 'outlined'}
                  color="secondary"
                />
              </div>
            );
          })}
        </div>
      )}
      {templates.length && Object.keys(modules).length && (
        <ContestsTemplatesTable templates={templates} modules={modules} onTemplateSelected={onTemplateSelected} />
      )}
      {selectedTemplate && (
        <ModalWithButton
          closeModal={() => setSelectedTemplate(undefined)}
          height={window.innerHeight * 0.9}
          open={selectedTemplate !== undefined}
          width={window.innerWidth * 0.75}
          overflow="scroll"
          openModal={() => {
            console.log('nothing yet');
          }}
          button={{
            element: <></>,
          }}
          children={[
            <NewContestForm
              title={selectedTemplate.title}
              startDate={selectedTemplate.start_date}
              endDate={selectedTemplate.end_date}
              about={selectedTemplate.about}
              description={selectedTemplate.description}
              prize={selectedTemplate.prize}
              rules={selectedTemplate.rules}
              hard_deadline={selectedTemplate.hard_deadline}
              pairs={selectedTemplate.problems.map((problem) => ({
                problem: problem.title,
                quota: 1, // TODO
              }))}
              url={`${URLs.updateContest}/${selectedTemplate.id}`}
              method="put"
              onSuccess={() => {
                setSelectedTemplate(undefined);
                forceUpdate();
              }}
              modalTitle={'Edit contest template'}
              moduleId={selectedTemplate.module_id}
            />,
          ]}
        />
      )}
    </div>
  );
};
