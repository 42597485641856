import {useContext, useEffect, useMemo, useReducer, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {ArrowBackIosNew, Edit} from '@mui/icons-material';
import {Grid, IconButton, Skeleton} from '@mui/material';

import {CodeEditor} from '../code-editor/CodeEditor';
import {createSubmissionRequest} from '../../api/Requests';
import {sendGetRequest, sendPostRequest, URLs} from '../../api';
import {Button, ModalWithButton, NewProblemForm as Form, Switch} from '..';
import {Submissions} from '../submissions/Submissions';
import {Problem, Role, Submission, ButtonSize as Size, Color} from '../../types';
import {Description} from './Description';
import AppContext, {ContextData} from '../../context/AppContext';

export interface CardProps {
  problemId: number;
  contestId: number;
  groupContestId: number;
}

export const Card = ({problemId, contestId, groupContestId}: CardProps) => {
  const {dispatchError, user, checkTimeLimit, languages} = useContext(AppContext);
  const navigate = useNavigate();

  const [code, setCode] = useState<string | null>(null);
  const [problem, setProblem] = useState<Problem | null>(null);
  const [switchTo, setSwitchTo] = useState<number | undefined>(undefined);
  const [pending, setPending] = useState<boolean>(false);
  const [latestSubmission, setLatestSubmission] = useState<Submission>();

  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const [editMode, setEditMode] = useState(false);
  const toggleEditMode = () => setEditMode(!editMode);

  const isPrivileged = useMemo(() => {
    if (!user?.id) return false;

    let currentUserId = user.id;

    if (typeof currentUserId === 'string') {
      currentUserId = parseInt(currentUserId, 10);
    }

    return currentUserId === problem?.created_by || user?.role === Role.ADMIN || user?.role === Role.CREATOR;
  }, [problem, user]);

  const pendingKey = useMemo(() => `pending_c${contestId}_p${problemId}`, [contestId, problemId]);

  const onSubmit = () => {
    if (!problem) return;

    const request = {
      code: code,
    } as createSubmissionRequest;

    setSwitchTo(1);
    localStorage.setItem(pendingKey, new Date().toString());

    // Normally, the storage event is dispatched only if the change was done from a different page
    window.dispatchEvent(new Event('storage'));
    setPending(true);

    setTimeout(() => {
      const pending = localStorage.getItem(pendingKey);
      if (pending) {
        checkTimeLimit(pending, pendingKey);
      } else {
        setPending(false);
      }
    }, ContextData.TIME_LIMIT);

    sendPostRequest(URLs.createSubmission(problem.id, contestId, groupContestId), request)
      .then((_response) => {
        // Navigate to the "Submissions" tab
        localStorage.removeItem(pendingKey);
        window.dispatchEvent(new Event('storage'));
        forceUpdate();
        setPending(false);
        setLatestSubmission(Submission.fromResponse(_response.data));
      })
      .catch((err) => {
        if (err) {
          if (err.response && err.response.data && err.response.data.error) {
            dispatchError(err.response.data.error, '/problems');
          } else {
            dispatchError('There was an error. Please try again later', '/problems');
          }
        }
      });
  };

  const getTabs = (isPrivileged: boolean) => {
    const commonTabs = [
      {name: 'Details', component: <Description {...(problem ?? ({} as Problem))} />},
      {
        name: 'My submissions',
        component: (
          <Submissions
            contestId={contestId}
            problem={problem ?? ({} as Problem)}
            setCode={(submissionCode) => {
              if (
                code === problem?.skeleton ||
                window.confirm('Are you sure you want to restore your code? All unsaved changes will be lost.')
              ) {
                setCode(submissionCode);
              }
            }}
            pending={pending}
            latestSubmission={latestSubmission}
          />
        ),
      },
    ];

    return commonTabs;
  };

  useEffect(() => {
    sendGetRequest(`${URLs.getProblem}/${problemId}/?contest_id=${isNaN(contestId) ? -1 : contestId}`, {})
      .then((response) => {
        setProblem(new Problem(response.data));
        setCode(response.data.skeleton);
        forceUpdate();
      })
      .catch((err) => {
        if (err) {
          if (err) {
            if (err.response && err.response.data && err.response.data.error) {
              dispatchError(err, '/problems');
            } else {
              dispatchError('There was an error. Please try again later', '/problems');
            }
          }
        }
      });
  }, [problemId, contestId]);

  return (
    <div className="min-h-[80vh]">
      {!problem ? (
        <Skeleton animation="pulse" variant="rectangular" className="h-[80vh] w-full" />
      ) : (
        <div className="grid grid-cols-12 p-3 w-full">
          <div className="col-span-5 max-h-[80vh] overflow-y-scroll">
            <div className="flex justify-between px-4">
              <IconButton onClick={() => navigate(-1)}>
                {' '}
                <ArrowBackIosNew sx={{fontSize: 30}} className="text-white" />{' '}
              </IconButton>
              {isPrivileged && (
                <ModalWithButton
                  button={{
                    element: (
                      <IconButton onClick={toggleEditMode}>
                        {' '}
                        <Edit sx={{fontSize: 24}} className="text-white" />{' '}
                      </IconButton>
                    ),
                  }}
                  children={[
                    <Form
                      title={problem.title}
                      language={languages[problem.language_id]}
                      difficulty={problem.difficulty}
                      categories={problem.categories ?? []}
                      description={problem.description}
                      visible={problem.visible ? 'public' : 'private'}
                      skeleton={problem.skeleton}
                      test={problem.tests}
                      url={`${URLs.updateProblem}/${problem.id}`}
                      method="put"
                      onSuccess={() => window.location.reload()}
                    />,
                  ]}
                  className="allowScrollbar"
                  closeModal={toggleEditMode}
                  height={window.innerHeight * 0.9}
                  open={editMode}
                  openModal={toggleEditMode}
                  overflow="scroll"
                  width={window.innerWidth * 0.75}
                />
              )}
            </div>
            <div className="box-content justify-between h-fit box overflow-y-scroll break-words">
              <Switch
                chosen={switchTo}
                direction="horizontal"
                justifyOptions="space-evenly"
                options={getTabs(user?.role !== Role.STUDENT)}
                variant="outlined"
              />
            </div>
          </div>
          <div className="col-span-7">
            <div className="flex flex-col">
              <CodeEditor
                className="min-h-[80vh]"
                onChange={(value) => {
                  value && setCode(value);
                }}
                code={code ?? ''}
                enableMinimap
              />
              <Button color={Color.PRIMARY} fullWidth={true} label="Run tests" onClick={onSubmit} size={Size.LARGE} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
